<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      revenues: [],
      waterIncome: [],
      cashAccounts: [],
      others: [],
      selctedRevenue: [
        { id: 1, name: "المنح", name_en: "Grants" },
        { id: 2, name: "ايرادات اخرى", name_en: "Other Revenues" },
      ],
      isSelected: false,
      levelTowRevenues: [],
      grants: [],
      selectedOptions: null,
      revenue: {},
      branch_id: null,
    };
  },
  methods: {
    addRevenue() {
      this.revenue.is_editable = true;
      const { id, ...data } = this.revenue;
      console.log(id, data);
      data.id = id;
      console.log(data);
      this.http
        .post("level-three-chart-of-accounts-copy-1/revenues", data)
        .then((res) => {
          if (res.status) {
            this.isSelected = false;
            this.revenue = {};
            this.grants = [];
            this.others = [];
            this.waterIncome = [];
            this.getRevenues();
            return console.log("done");
          }
          return console.log("not done");
        });
    },
    getCashAccounts() {
      this.http.post("transactions/cash-accounts", {}).then((res) => {
        this.cashAccounts = res.data;
      });
    },
    showRevenue() {
      console.log(this.selctedRevenue);
      this.isSelected = true;
      let self = this;
      if (this.selectedOptions == 0) {
        self.levelTowRevenues = this.grants;
      } else {
        self.levelTowRevenues = this.others;
      }

      console.log(this.levelTowRevenues);
    },
    getRevenues() {
      this.http
        .post("main-chart-of-accounts-types/revenues", {
          branch_id: this.branch_id,
        })
        .then((resp) => {
          let self = this;
          resp.data[0].level_two_chart_of_accounts.forEach((income) => {
            income.level_three_chart_of_accounts.forEach((levelThree) => {
              self.waterIncome.push(levelThree);
            });
          });
          resp.data[1].level_two_chart_of_accounts.forEach((grant) => {
            grant.level_three_chart_of_accounts.forEach((levelThree) => {
              self.grants.push(levelThree);
            });
          });
          resp.data[2].level_two_chart_of_accounts.forEach((other) => {
            other.level_three_chart_of_accounts.forEach((levelThree) => {
              self.others.push(levelThree);
            });
          });
          console.log(resp.data);
          console.log(this.grants);
          console.log(this.others);
        });
    },
  },
  created() {
    const current_user = JSON.parse(localStorage.currentUser);
    this.branch_id = current_user.branch_id;
    this.getRevenues();
    this.getCashAccounts();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
      :title="$t('menu.menuitems.accounting.subItems.revenues')"
    />

    <div :dir="$i18n.locale == 'ar' ? 'ltr' : 'ltr'" class="row">
      <div class="col-6"></div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#addRevenue"
          class="btn btn-light float-end mb-4"
        >
          <span
            class="bx bxs-plus-circle float-end fa-2x"
            style="color: #2a3042 !important"
          ></span>
        </button>
      </div>
    </div>
    <!--Add Revenus-->
    <div
      class="modal fade"
      id="addRevenue"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div class="container px-0">
              <form @submit.prevent="addRevenue()">
                <div class="modal-header">
                  <h5 class="modal-title mt-3" id="exampleModalLabel">
                    {{ $t("popups.add") }}
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style="position: relative; left: 10px"
                  ></button>
                </div>
                <div class="mb-3 mt-4">
                  <label for="exampleInputEmail1" class="form-label">
                    <!-- {{ $t("popups.tariff") }} -->
                    {{ $t("revenues.select_type") }}
                  </label>
                  <select
                    @change="showRevenue()"
                    class="form-select"
                    aria-label="Default select example"
                    v-model="selectedOptions"
                  >
                    <option
                      v-for="(revenue, index) in selctedRevenue"
                      :key="revenue"
                      :value="index"
                    >
                      {{
                        $i18n.locale == "ar" ? revenue.name : revenue.name_en
                      }}
                    </option>
                  </select>
                </div>

                <div class="mb-3 mt-4" v-if="isSelected">
                  <label for="exampleInputEmail1" class="form-label">
                    {{ $t("revenues.select_revenues") }}
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="revenue.id"
                  >
                    <option
                      v-for="levelTow in levelTowRevenues"
                      :key="levelTow"
                      :value="levelTow.id"
                    >
                      {{
                        $i18n.locale == "ar" ? levelTow.name : levelTow.name_en
                      }}
                    </option>
                  </select>
                </div>
                <div class="mb-3 mt-4">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("popups.value")
                  }}</label>
                  <input
                    type="number"
                    v-model="revenue.value"
                    class="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
                <div class="mb-3 mt-4" v-if="isSelected">
                  <label for="exampleInputEmail1" class="form-label">
                    {{ $t("revenues.select_account") }}
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="revenue.cash_account_id"
                  >
                    <option
                      v-for="account in cashAccounts"
                      :key="account"
                      :value="account.id"
                    >
                      {{
                        $i18n.locale == "ar" ? account.name : account.name_en
                      }}
                    </option>
                  </select>
                </div>

                <div
                  class="modal-footer"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                >
                  <button type="submit" class="btn btn-primary">
                    {{ $t("popups.add") }}
                  </button>
                  <button
                    data-bs-dismiss="modal"
                    type="button"
                    class="btn btn-danger"
                  >
                    {{ $t("popups.cancel") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--End Add Revenus-->
    <b-tabs
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      justified
      nav-class="nav-tabs-custom"
      content-class="p-3 text-muted"
    >
      <b-tab active>
        <template v-slot:title>
          <span
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            class="d-inline-block d-sm-none"
          >
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{
            $t("revenues.tab_one_title")
          }}</span>
        </template>
        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
          <thead>
            <tr class="text-light" style="background-color: #2a3042 !important">
              <th scope="col">{{ $t("revenues.name") }}</th>
              <th scope="col">{{ $t("revenues.created") }}</th>
              <th scope="col">{{ $t("revenues.updated") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="income in waterIncome" :key="income" class="">
              <td>
                {{ $i18n.locale == "ar" ? income?.name : income?.name_en }}
              </td>
              <td>{{ income?.created?.split("T")[0] }}</td>
              <td>{{ income?.updated?.split("T")[0] }}</td>
            </tr>
          </tbody>
        </table>
        <!-- end  table -->
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <span
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            class="d-inline-block d-sm-none"
          >
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{
            $t("revenues.tab_two_title")
          }}</span>
        </template>
        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
          <thead>
            <tr class="text-light" style="background-color: #2a3042 !important">
              <th scope="col">{{ $t("revenues.name") }}</th>
              <th scope="col">{{ $t("revenues.created") }}</th>
              <th scope="col">{{ $t("revenues.updated") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="grant in grants" :key="grant" class="">
              <td>{{ $i18n.locale == "ar" ? grant?.name : grant?.name_en }}</td>
              <td>{{ grant?.created?.split("T")[0] }}</td>
              <td>{{ grant?.updated?.split("T")[0] }}</td>
            </tr>
          </tbody>
        </table>
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <span
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            class="d-inline-block d-sm-none"
          >
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{
            $t("revenues.tab_three_title")
          }}</span>
        </template>
        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
          <thead>
            <tr class="text-light" style="background-color: #2a3042 !important">
              <th scope="col">{{ $t("revenues.id") }}</th>
              <th scope="col">{{ $t("revenues.name") }}</th>
              <th scope="col">{{ $t("revenues.created") }}</th>
              <th scope="col">{{ $t("revenues.updated") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(other, index) in others" :key="other" class="">
              <td>{{ index + 1 }}</td>
              <td>{{ $i18n.locale == "ar" ? other?.name : other?.name_en }}</td>
              <td>{{ other?.created?.split("T")[0] }}</td>
              <td>{{ other?.updated?.split("T")[0] }}</td>
            </tr>
          </tbody>
        </table>
      </b-tab>
    </b-tabs>
  </Layout>
</template>
<style scoped></style>
